import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Separator } from 'app/components/Common/Separator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps
  description?: string
  images?: ImageProps[]
  title?: string
}

export const Position = memo(function Position({
  cta,
  description,
  images,
  title,
}: Props) {
  return (
    <Container>
      <Separator />
      <Wrapper>
        {title ? (
          <Fade bottom>
            <Title>{title}</Title>
          </Fade>
        ) : null}
        {description ? (
          <Fade bottom>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </Fade>
        ) : null}
        {cta ? (
          <Fade>
            <Button variant="simple" {...cta} />
          </Fade>
        ) : null}
      </Wrapper>
      {images ? (
        <Images row wrap>
          {images.slice(0, 3).map((item, index) => (
            <LazyLoad key={index}>
              <Image {...item} />
            </LazyLoad>
          ))}
        </Images>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 6rem auto;
  padding: 0 5vw;
`

const Wrapper = styled.div`
  max-width: 39vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 3.75rem auto 0;
  padding: 0 3rem 5.75rem;
  position: relative;
  z-index: 2;
  text-align: center;

  @media (max-width: 1199px) {
    max-width: 60vw;
  }

  @media (max-width: 767px) {
    max-width: none;
    padding: 0;
  }
`

const Title = styled.h2`
  max-width: 28.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin: auto;

  @media (max-width: 767px) {
    font-size: 1.375rem;
    line-height: 2.375rem;
  }

  @media (max-width: 767px) {
    max-width: none;
  }
`

const Description = styled.div`
  max-width: 21.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  margin: 1.875rem auto 0;

  @media (max-width: 767px) {
    max-width: none;
    margin-top: 1.5rem;
  }
`

const Images = styled(FlexBox)`
  margin: -12.5rem -1.5rem 0 0;
  > div {
    width: calc(33.333% - 1.5rem);
    margin-right: 1.5rem;
    padding-bottom: 42%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 767px) {
    margin: 3rem -0.75rem 0 0;
    > div {
      width: calc(33.333% - 0.75rem);
      margin-right: 0.75rem;
    }
  }
`
