import styled from '@emotion/styled'
import { Benefits } from 'app/components/Benefits'
import { Gallery } from 'app/components/Gallery'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { OffersSlider } from 'app/components/OffersSlider'
import { Position } from 'app/components/Position'
import { RoomsSlider } from 'app/components/RoomsSlider'
import { SEO } from 'app/components/SEO'
import { Social } from 'app/components/Social'
import { Sticker } from 'app/components/Sticker'
import { Visual } from 'app/components/Visual'
import { ZigZagList } from 'app/components/ZigZag'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.benefitsProps ? <Benefits {...context.benefitsProps} /> : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.roomsSliderProps ? (
        <RoomsSlider {...context.roomsSliderProps} />
      ) : null}
      {context.zigZagProps ? <ZigZagList {...context.zigZagProps} /> : null}
      {context.visualProps ? <Visual {...context.visualProps} /> : null}
      {context.positionProps ? <Position {...context.positionProps} /> : null}
      {context.offersSliderProps ? (
        <OffersSlider {...context.offersSliderProps} />
      ) : null}
      {context.galleryProps ? <Gallery {...context.galleryProps} /> : null}
      {context.socialProps ? <Social {...context.socialProps} /> : null}
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix}
      />
      {context.stickerProps ? <Sticker {...context.stickerProps} /> : null}
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
